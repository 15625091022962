import classNames from "classnames"
import filter from "lodash/filter"
import isEmpty from "lodash/isEmpty"

import RSVPConfig from "RSVPConfig"
import Card from "components/shared/Card"
import { useTranslation } from "react-i18next"

import styles from "./ParkingSession.module.scss"

const ParkingInstructionsCard = () => {
  const { t } = useTranslation()
  const { features } = RSVPConfig

  if (!features.hasParkingInstructions) {
    return null
  }

  const instructions = t("parkingSession.instructions", { returnObjects: true })
  // Filter out instructions without text
  const validInstructions = filter(
    instructions,
    (instruction) => !isEmpty(instruction.text)
  )

  return (
    <Card className={classNames(styles.card, styles.instructionsCard)}>
      <div className={styles.instructionsWrapper}>
        <div className={styles.instructionsHeadline}>Parking Instructions</div>
        <ol className={styles.instructionsList}>
          {validInstructions.map((instruction) => (
            <li className={styles.instructionsListItem}>
              <div className={styles.instructionsCopy}>
                {instruction.title && (
                  <div className={styles.instructionsTitle}>
                    {instruction.title}
                  </div>
                )}
                {instruction.text.trimEnd()}&nbsp;
                {instruction.url && (
                  <a href={instruction.url} target="_blank" rel="noreferrer">
                    {instruction.link_text}
                  </a>
                )}
              </div>
            </li>
          ))}
        </ol>
      </div>
    </Card>
  )
}

export default ParkingInstructionsCard
