import { useState, useEffect, useCallback } from "react"
import { useHistory } from "react-router-dom"
import { useLazyQuery, useReactiveVar } from "@apollo/client"
import { DateTime } from "luxon"
import { selectedDayVar, cartIdVar, cartPromoCodeVar } from "cache"
import PropTypes from "prop-types"
import toNumber from "lodash/toNumber"
import get from "lodash/get"
import mapValues from "lodash/mapValues"
import map from "lodash/map"
import isNil from "lodash/isNil"
import isEmpty from "lodash/isEmpty"
import { useLoginState } from "components/Login/hooks"
import Calendar from "components/shared/Calendar"
import { RATE_AVAILABILITY_COLORS, STEPS } from "shared/constants"
import { formatToDayStartISO, formatDate } from "shared/formatting/datetime"

import ExpandableCard from "./ExpandableCard"
import {
  useRsvpPortalZone,
  useClaimCart,
  useAddPromoToCart,
  useCreateCart
} from "./hooks"
import { cartStartTime } from "./helpers"
import {
  PublicParkingAvailability,
  PrivateParkingAvailability
} from "./operations"

import styles from "./SelectDate.module.scss"

const SelectDate = ({
  step,
  setStep,
  onCartStartTimeChange,
  setDetailedRatesAvailability
}) => {
  const history = useHistory()
  const selectedDay = useReactiveVar(selectedDayVar)
  const setSelectedDay = selectedDayVar
  const cartId = useReactiveVar(cartIdVar)

  const { loading: zoneLoading, data: zoneData } = useRsvpPortalZone()
  const timezone = get(zoneData, "zone.timezone")
  const parkingInventoryId = get(zoneData, "zone.parkingInventoryId")
  const startOfToday = formatToDayStartISO(DateTime.now(), { timezone })
  const [createCartRetryCount, setCreateCartRetryCount] = useState(0)
  const [calendarStartDay, setCalendarStartDay] = useState(null)
  const [isCartReady, setIsCartReady] = useState(false)

  const [isLoggedIn] = useLoginState()
  const [claimCart, { loading: claimCartLoading }] = useClaimCart()

  // We need to wait for a promo code to be added to the cart before we can proceed
  // if the user is logged in and has a promo code
  const [addPromoToCart, { data: addPromoData, loading: addPromoLoading }] =
    useAddPromoToCart()
  const promoCode = get(addPromoData, "addPromoToCart.cart.promoCode", null)
  const activePromoCode = !isEmpty(promoCode)

  const [createCart, { loading: createCartLoading, error: createCartError }] =
    useCreateCart(timezone)

  const [
    publicParkingAvailability,
    { data: detailWithRatesData, loading: detailWithRatesLoading }
  ] = useLazyQuery(PublicParkingAvailability)

  const [
    privateParkingAvailability,
    { data: privateDetailWithRatesData, loading: privateDetailWithRatesLoading }
  ] = useLazyQuery(PrivateParkingAvailability)

  const cartHelper = useCallback(async () => {
    if (!isEmpty(zoneData)) {
      // Make sure isCartReady is initialized to false before creating a cart
      setIsCartReady(false)
      await createCart()

      setCreateCartRetryCount(0)

      if (isLoggedIn && !isNil(cartPromoCodeVar())) {
        await claimCart()
        await addPromoToCart()
      }

      // we want to make sure that the cart is ready (i.e. promo code is added)
      // before we load the calendar (parking availability query)
      setIsCartReady(true)
    }
  }, [createCart, zoneData, claimCart, addPromoToCart, isLoggedIn])

  useEffect(() => {
    // createCartRetryCount incremented by 1 when there is a network error
    if (
      !isEmpty(createCartError) &&
      createCartError.networkError &&
      createCartRetryCount < 1
    ) {
      cartHelper()
      setCreateCartRetryCount((prev) => prev + 1)
      return
    }

    // After 1st retry, if there is a network error immmediately redirect to home
    if (createCartRetryCount === 1) {
      history.push("/")
      return
    }

    // createCartRetryCount is 0 when in initial call
    cartHelper()
  }, [cartHelper, createCartError, createCartRetryCount, history])

  // This effect will set the detailed parking availability
  // when the selected day changes
  // this data will be used in select rate component
  useEffect(() => {
    const selectedDayWithRates = activePromoCode
      ? get(privateDetailWithRatesData, [
          "privateParkingAvailability",
          selectedDay
        ])
      : get(detailWithRatesData, ["publicParkingAvailability", selectedDay])

    if (selectedDayWithRates) {
      setDetailedRatesAvailability(selectedDayWithRates)
    }
  }, [
    selectedDay,
    activePromoCode,
    detailWithRatesData,
    privateDetailWithRatesData,
    setDetailedRatesAvailability
  ])

  // This function will set the selected day
  const handleDateSelected = (e) => {
    const date = formatToDayStartISO(DateTime.fromJSDate(e.value), { timezone })
    // Get the detailed parking availability with rates for the selected day

    setSelectedDay(date)
    onCartStartTimeChange(date)
    setStep(STEPS.rate)
  }

  const handleDateChange = () => {
    setSelectedDay(null)
    setStep(STEPS.date)
  }

  // This function will get the detailed parking availability from the selected day
  const handlePageLoading = (e) => {
    // Shift last day as it seems it is always the start of the next month
    const { lastDay } = e
    lastDay.setDate(lastDay.getDate() - 1)

    const startTime = cartStartTime(startOfToday, timezone)
    // "o" is ordinal day (day of the year)
    const startDay = toNumber(DateTime.fromJSDate(e.firstDay).toFormat("o"))
    const endDay = toNumber(DateTime.fromJSDate(lastDay).toFormat("o"))
    const year = toNumber(DateTime.fromJSDate(e.month).toFormat("y"))

    // If detailWithRatesLoading is loading, we don't want to make another request
    // onPageLoading is called twice on every render.
    const loading = activePromoCode
      ? privateDetailWithRatesLoading
      : detailWithRatesLoading
    if (loading && startDay === calendarStartDay) return

    if (activePromoCode && isNil(cartId)) return

    const parkingAvailabilityQuery = activePromoCode
      ? privateParkingAvailability
      : publicParkingAvailability

    const variables = {
      id: parkingInventoryId,
      cartId, // cartId is only needed for private parking
      cartStartTime: startTime,
      startDay,
      endDay,
      year
    }
    parkingAvailabilityQuery({ variables })

    setCalendarStartDay(startDay)
  }

  const ratesAvailability = () => {
    const parkingAvailability = activePromoCode
      ? get(privateDetailWithRatesData, "privateParkingAvailability", {})
      : get(detailWithRatesData, "publicParkingAvailability", {})

    const availabilityByDay = mapValues(parkingAvailability, "status")

    return map(availabilityByDay, (value, date) => {
      let color = ""

      if (value.sold_out) {
        color = RATE_AVAILABILITY_COLORS.soldOut
      } else if (value.unavailable) {
        color = RATE_AVAILABILITY_COLORS.unavailable
      } else if (
        !value.sold_out &&
        !value.unavailable &&
        !value.reservation_not_needed
      ) {
        color = RATE_AVAILABILITY_COLORS.available
      }

      return {
        date,
        highlight: color
      }
    })
  }

  const datesAvailability = () => (
    <div className={styles.availability}>
      <div className={styles.available}>Available</div>
      <div className={styles.unavailable}>Unavailable</div>
      <div className={styles.soldOut}>Sold out</div>
      <div className={styles.noReservation}>No reservation needed</div>
    </div>
  )

  const isLoading =
    zoneLoading ||
    createCartLoading ||
    addPromoLoading ||
    claimCartLoading ||
    !isCartReady

  return (
    <div className="h-100">
      <ExpandableCard
        currentStep={step}
        step={STEPS.date}
        title="2. Date"
        subtitle={formatDate(selectedDay, { timezone })}
        buttonText="Change Date"
        onButtonClick={handleDateChange}
        isLoading={isLoading}
      >
        <Calendar
          defaultSelection={null}
          onChange={handleDateSelected}
          onPageLoading={handlePageLoading}
          colors={ratesAvailability()}
          min={startOfToday}
        />

        {datesAvailability()}
      </ExpandableCard>
    </div>
  )
}

SelectDate.propTypes = {
  step: PropTypes.string,
  setStep: PropTypes.func,
  activePromoCode: PropTypes.bool,
  onCartStartTimeChange: PropTypes.func,
  setDetailedRatesAvailability: PropTypes.func
}

export default SelectDate
