import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { Helmet } from "react-helmet"

import Layout from "components/Layout"
import Home from "components/Home"
import Login from "components/Login"
import Signup from "components/Signup"
import AddPromoCode from "components/AddPromoCode"
import AccountPromoCodes from "components/AccountPromoCodes"
import PastAccountPromoCodes from "components/PastAccountPromoCodes"
import ParkingSelection from "components/ParkingSelection"
import PreRedirect from "components/PreRedirect"
import PostPurchase from "components/PostPurchase"
import ParkingSession from "components/ParkingSession"
import ParkingSessions from "components/ParkingSessions"
import Settings from "components/Settings"
import Consume from "components/Consume"
import SisterSiteLogoutRedirect from "components/SisterSiteLogoutRedirect"
import SisterSiteLogoutConsume from "components/SisterSiteLogoutConsume"
import SisterSiteRedirect from "components/SisterSiteRedirect"
import SisterSiteConsume from "components/SisterSiteConsume"

const titleTemplate = "%s | HONK"

export default () => {
  return (
    <Router>
      <Helmet titleTemplate={titleTemplate} />
      <Switch>
        <Layout>
          <Route exact path="/" component={Home} />
          <Route path="/login" component={Login} />
          <Route path="/signup" component={Signup} />
          <Route path="/auth-redirect" component={SisterSiteRedirect} />
          <Route path="/auth-consume" component={SisterSiteConsume} />
          <Route path="/code" component={AddPromoCode} />
          <Route path="/settings" component={Settings} />
          <Route path="/logout-redirect" component={SisterSiteLogoutRedirect} />
          <Route path="/logout-consume" component={SisterSiteLogoutConsume} />
          <Route exact path="/parking-codes" component={AccountPromoCodes} />
          <Route
            exact
            path="/parking-codes/past"
            component={PastAccountPromoCodes}
          />
          <Route path="/select-parking" component={ParkingSelection} />
          <Route path="/redirect-to-honk" component={PreRedirect} />
          <Route path="/post-purchase" component={PostPurchase} />
          <Route
            path="/parking-reservation/:parkingSessionId"
            component={ParkingSession}
          />
          <Route path="/parking-reservations" component={ParkingSessions} />
          <Route path="/consume/:url/:id?" component={Consume} />
        </Layout>
      </Switch>
    </Router>
  )
}
