import React from "react"
import PropTypes from "prop-types"
import { DateTime } from "luxon"
import classNames from "classnames"
import { Link } from "react-router-dom"
import isEmpty from "lodash/isEmpty"

import Card from "components/shared/Card"
import formatPrice from "shared/formatting/price"
import { useForceAuthEffect } from "components/Login/hooks"
import styles from "./ParkingSessions.module.scss"

const SessionCard = ({
  session: {
    zone,
    startTime,
    transactionHistory,
    hashid,
    voided: isCancelled,
    rateName
  }
}) => {
  useForceAuthEffect()

  return (
    <Link to={`parking-reservation/${hashid}`} className="text-decoration-none">
      <Card className={styles.card}>
        <div className={styles.cardBody}>
          <div>
            <div className={styles.cardHeader}>
              {zone.address.street}
              {isCancelled && (
                <div
                  className={classNames(
                    styles.cancelledBadge,
                    "badge text-dark mx-2"
                  )}
                >
                  Cancelled
                </div>
              )}
            </div>
            <div className={classNames(styles.cardCopy, "text-muted")}>
              <div className={styles.dateRateCopy}>
                <div>
                  {DateTime.fromISO(startTime).toLocaleString(
                    DateTime.DATE_MED
                  )}
                </div>
                {!isEmpty(rateName) && (
                  <div className={styles.rateName}>• {rateName}</div>
                )}
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center h-100 fw-bold">
            {formatPrice({ value: transactionHistory.total })}
            <i className="bi-chevron-right h5 mb-0" />
          </div>
        </div>
      </Card>
    </Link>
  )
}

SessionCard.propTypes = {
  session: PropTypes.shape({
    hashid: PropTypes.string,
    rateName: PropTypes.string,
    startTime: PropTypes.string,
    voided: PropTypes.bool,
    transactionHistory: PropTypes.shape({
      total: PropTypes.number
    }),
    zone: PropTypes.shape({
      zoneId: PropTypes.string,
      address: PropTypes.shape({
        street: PropTypes.string,
        city: PropTypes.string
      })
    })
  })
}

export default SessionCard
