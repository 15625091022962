import { useState, useEffect } from "react"
import { useReactiveVar } from "@apollo/client"
import Toast from "react-bootstrap/Toast"
import ToastContainer from "react-bootstrap/ToastContainer"
import classNames from "classnames"
import isEmpty from "lodash/isEmpty"

import { errorsVar } from "cache"
import { errorMessages } from "./constants"
import styles from "./ErrorToast.module.scss"

// Do not show error message for the following error msg.
const ERROR_WHITELIST = new Set(["email_address_redirect"])

const ErrorToast = () => {
  const [isShowing, setIsShowing] = useState(false)
  const errors = useReactiveVar(errorsVar)

  useEffect(() => {
    if (errors?.length === 1 && ERROR_WHITELIST.has(errors[0])) return

    if (!isEmpty(errors)) {
      setIsShowing(true)
    }
  }, [errors])

  if (isEmpty(errors)) return null

  const handleClose = () => {
    setIsShowing(false)
    errorsVar(null)
  }

  return (
    <ToastContainer
      className={classNames(
        "p-3 w-100 d-flex justify-content-center",
        styles.toastContainer
      )}
      position="bottom-center"
    >
      <div className="col-sm-9 col-md-6">
        {errors.map((error) => {
          return (
            <Toast
              onClose={handleClose}
              className={styles.toast}
              show={isShowing}
              delay="60000"
              autohide
              bg="danger"
              key={error}
            >
              <Toast.Header className={classNames(styles.body, "p-3")}>
                <div className="me-auto">{errorMessages[error]}</div>
              </Toast.Header>
            </Toast>
          )
        })}
      </div>
    </ToastContainer>
  )
}

export default ErrorToast
