import { gql } from "@apollo/client"

const CorePromoCodeFields = gql`
  fragment CorePromoCodeFields on PromoCode {
    hashid
    redeemCode
    shortDesc
    totalUses
    totalUsesCount
    expiry
  }
`
export const AccountPromoCodes = gql`
  ${CorePromoCodeFields}
  query AccountPromoCodes($rsvpPortalId: ID) {
    v2AccountPromoCodes(rsvpPortalId: $rsvpPortalId) {
      ...CorePromoCodeFields
      timezone
      startDate
      expiry
      locationIds
      promoCodesRates {
        activeSessionLimit
        activeSessionCount
        rate {
          hashid
          description
          zone {
            hashid
          }
        }
      }
    }
  }
`

export const ReadPastAccountPromoCodes = gql`
  ${CorePromoCodeFields}
  query ReadPastAccountPromoCodes($rsvpPortalId: ID) {
    pastAccountPromoCodes(rsvpPortalId: $rsvpPortalId) {
      ...CorePromoCodeFields
      isExpired @client
    }
  }
`
export const ReadCurrentAccountPromoCodes = gql`
  ${CorePromoCodeFields}
  query ReadCurrentAccountPromoCodes($rsvpPortalId: ID) {
    currentAccountPromoCodes(rsvpPortalId: $rsvpPortalId) {
      ...CorePromoCodeFields
      timezone
      startDate
      expiry
      locationIds
      promoCodesRates {
        activeSessionLimit
        activeSessionCount
        rate {
          hashid
          description
          zone {
            hashid
          }
        }
      }
    }
  }
`
