import { useMutation, useQuery } from "@apollo/client"
import { cartIdVar, cartPromoCodeVar, errorsVar, zoneIdVar } from "cache"
import isEmpty from "lodash/isEmpty"
import { DateTime } from "luxon"
import { formatToDayStartISO } from "shared/formatting/datetime"
import { cartStartTime } from "./helpers"

import {
  CreateCart,
  ClaimCart,
  AddPromoToCart,
  Zone,
  RsvpPortal
} from "./operations"

export const useRsvpPortal = () => {
  return useQuery(RsvpPortal, {
    variables: { id: process.env.REACT_APP_RSVP_PORTAL_ID }
  })
}

export const useRsvpPortalZone = () => {
  const zoneId = zoneIdVar()
  return useQuery(Zone, {
    variables: { id: zoneId },
    skip: !zoneId
  })
}

export const useZone = () => {
  return useQuery(Zone, {
    variables: { id: process.env.REACT_APP_RSVP_ZONE_ID }
  })
}

// Extracted CreateCart mutation to keep AccountPromoCodes cleaner since variables are constant anyway
export const useCreateCart = (timezone) => {
  const startOfToday = formatToDayStartISO(DateTime.now(), { timezone })
  const startTime = cartStartTime(startOfToday, timezone)

  return useMutation(CreateCart, {
    variables: {
      input: {
        startTime,
        zoneId: zoneIdVar(),
        productType: "RESERVE"
      }
    },
    onCompleted: ({ createCart: { cart, errors } }) => {
      if (!isEmpty(errors)) {
        errorsVar(errors)
      } else {
        errorsVar(null)
        cartIdVar(cart.hashid)
      }
    }
  })
}

export const useClaimCart = () => {
  const cartId = cartIdVar()
  return useMutation(ClaimCart, {
    variables: {
      input: { id: cartId }
    },
    onCompleted: ({ claimCart: { errors } }) => {
      if (!isEmpty(errors)) {
        errorsVar(errors)
      } else {
        errorsVar(null)
      }
    }
  })
}

export const useAddPromoToCart = () => {
  const cartId = cartIdVar()
  const promoCode = cartPromoCodeVar()
  return useMutation(AddPromoToCart, {
    variables: {
      input: { cartId, promoCode, validate: false }
    },
    onCompleted: ({ addPromoToCart: { errors } }) => {
      if (!isEmpty(errors)) {
        errorsVar(errors)
      } else {
        errorsVar(null)
      }
    }
  })
}
