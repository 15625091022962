import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import { Helmet } from "react-helmet"
import isEmpty from "lodash/isEmpty"
import qs from "query-string"
import { AUTH_KEY } from "shared/constants"
import FullPageLoader from "components/shared/FullPageLoader"

const SisterSiteConsume = () => {
  const { search } = useLocation()
  useEffect(() => {
    const { oaTag, r: destinationUrl } = qs.parse(search)
    const destUrl = decodeURIComponent(destinationUrl)

    if (!isEmpty(oaTag)) {
      localStorage.setItem(AUTH_KEY, oaTag)
    }
    window.location.assign(destUrl)
  }, [])

  return (
    <>
      <Helmet title="Logging in" />
      <FullPageLoader
        message="Logging in"
      />
    </>
  )
}

export default SisterSiteConsume
