import { gql } from "@apollo/client"

export const ChangeCartStartTime = gql`
  mutation ChangeCartStartTime($input: ChangeCartStartTimeInput!) {
    changeCartStartTime(input: $input) {
      cart {
        hashid
        startTime
      }
      errors
    }
  }
`

export const GetRates = gql`
  query GetRates($cartId: ID!) {
    v2CartRates(cartId: $cartId) {
      hashid
      price
      description
      promoRate
      behaviourType
      freeFlag
    }
  }
`

export const PublicParkingAvailability = gql`
  query PublicParkingAvailability(
    $id: ID!
    $cartStartTime: String!
    $startDay: Int!
    $endDay: Int!
    $year: Int!
  ) {
    publicParkingAvailability(
      id: $id
      cartStartTime: $cartStartTime
      startDay: $startDay
      endDay: $endDay
      year: $year
    )
  }
`

export const PrivateParkingAvailability = gql`
  query PrivateParkingAvailability(
    $id: ID!
    $cartId: ID!
    $cartStartTime: String!
    $startDay: Int!
    $endDay: Int!
    $year: Int!
  ) {
    privateParkingAvailability(
      id: $id
      cartId: $cartId
      cartStartTime: $cartStartTime
      startDay: $startDay
      endDay: $endDay
      year: $year
    )
  }
`

export const AddRateToCart = gql`
  mutation AddRateToCart($input: AddRateToCartInput!) {
    addRateToCart(input: $input) {
      cart {
        id
        hashid
      }
      errors
    }
  }
`

export const CreateCart = gql`
  mutation CreateCart($input: CreateCartInput!) {
    createCart(input: $input) {
      cart {
        hashid
      }
      errors
    }
  }
`

export const ClaimCart = gql`
  mutation ClaimCart($input: ClaimCartInput!) {
    claimCart(input: $input) {
      cart {
        hashid
      }
      errors
    }
  }
`

export const AddPromoToCart = gql`
  mutation AddPromoToCart($input: AddPromoToCartInput!) {
    addPromoToCart(input: $input) {
      cart {
        hashid
        promoCode {
          hashid
          shortDesc
          redeemCode
          promoCodesRates {
            rate {
              hashid
              description
              zone {
                hashid
              }
            }
          }
        }
      }
      errors
    }
  }
`

export const Zone = gql`
  query Zone($id: ID!) {
    zone(id: $id) {
      hashid
      parkingInventoryId
      timezone
      htmlDescription
    }
  }
`

export const RsvpPortal = gql`
  query RsvpPortal($id: ID!) {
    rsvpPortal(id: $id) {
      hashid
      zones {
        hashid
        zoneId
        address {
          street
        }
        parkingInventoryId
      }
    }
  }
`

export const ValidateCartPromoCode = gql`
  mutation ValidateCartPromoCode($input: ValidateCartPromoCodeInput!) {
    validateCartPromoCode(input: $input) {
      cart {
        hashid
      }
      errors
    }
  }
`
