import { Link } from "react-router-dom"
import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
import classNames from "classnames"
import { Helmet } from "react-helmet"
import { Form } from "react-bootstrap"
import { accountPromoCodeVar } from "cache"
import RSVPConfig from "RSVPConfig"

import Hero from "components/shared/Hero"
import ErrorToast from "components/shared/ErrorToast"
import { PROMO_CODE_KEY } from "shared/constants"
import { urlAfterAuth, useLoginState } from "components/Login/hooks"
import emailImg from "assets/images/email.png"

import styles from "./AddPromoCode.module.scss"

const AddPromoCode = ({ history }) => {
  const [t] = useTranslation()
  const [promoCode, setPromoCode] = useState("")
  const [validated, setValidated] = useState(false)
  const [isLoggedIn] = useLoginState()
  const { features } = RSVPConfig

  useEffect(() => {
    setPromoCode(accountPromoCodeVar())
    accountPromoCodeVar(null)
  }, [])

  const handleSubmit = (e) => {
    const form = e.target

    e.preventDefault()
    if (form.checkValidity() === false) {
      e.stopPropagation()
    } else {
      localStorage.setItem(PROMO_CODE_KEY, promoCode)
      accountPromoCodeVar(promoCode)

      const redirectUrl = "/parking-codes"
      if (!isLoggedIn) {
        urlAfterAuth(redirectUrl)
        history.push("/signup")
      } else {
        history.push(redirectUrl)
      }
    }
    setValidated(true)
  }

  return (
    <div className="d-flex flex-column h-100">
      <Helmet title="Add Parking Code" />
      <Hero>
        <img src={emailImg} alt="ticket" className="mx-auto d-block pb-4" />
      </Hero>
      <div className={classNames(styles.formWrapper, "container-sm")}>
        <div className="row justify-content-center">
          <div className="col-md-8 col-xl-6">
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <div className="mb-3">
                <label
                  htmlFor="promoCode"
                  className="form-label d-block text-center mb-4 px-3"
                >
                  {t("addPromoCode.label")}
                </label>
                {features.hasPromoCodeWarning && (
                  <div className="form-label d-block text-center mb-4 px-3">
                    Only one code can be redeemed per account. For additional
                    codes, please use separate accounts.
                  </div>
                )}
                <input
                  id="promoCode"
                  className="form-control"
                  placeholder="Enter your parking code"
                  required
                  onChange={(e) => {
                    setPromoCode(e.target.value)
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a valid code.
                </Form.Control.Feedback>
              </div>
              <div className="d-grid">
                <button
                  type="submit"
                  className="btn btn-primary btn-lg mt-3 mb-4 fw-bold"
                >
                  Submit
                </button>
              </div>
            </Form>
            {!isLoggedIn && (
              <div className="text-center">
                Are you a returning user? &nbsp;
                <Link to="/login">Log in to view your parking codes</Link>
              </div>
            )}
            {isLoggedIn && (
              <div className="text-center">
                Already have a code? &nbsp;
                <Link to="/parking-codes">View your parking codes</Link>
              </div>
            )}
          </div>
        </div>
      </div>
      <ErrorToast />
    </div>
  )
}

AddPromoCode.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
}

export default AddPromoCode
