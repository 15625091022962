import Helmet from "react-helmet"
import { Link } from "react-router-dom"
import { useQuery } from "@apollo/client"
import isEmpty from "lodash/isEmpty"
import classNames from "classnames"
import Hero from "components/shared/Hero"
import FullPageLoader from "components/shared/FullPageLoader"
import PromoCodeCard from "components/shared/PromoCodeCard"
import { useForceAuthEffect, useLoginState } from "components/Login/hooks"
import {
  AccountPromoCodes,
  ReadPastAccountPromoCodes
} from "components/AccountPromoCodes/operations"

import styles from "./PastAccountPromoCodes.module.scss"

const PastAccountPromoCodes = () => {
  useForceAuthEffect()
  const [isLoggedIn] = useLoginState()

  const { loading } = useQuery(AccountPromoCodes, {
    variables: { rsvpPortalId: process.env.REACT_APP_RSVP_PORTAL_ID },
    skip: !isLoggedIn
  })

  const { data: pastPromoData } = useQuery(ReadPastAccountPromoCodes, {
    variables: { rsvpPortalId: process.env.REACT_APP_RSVP_PORTAL_ID },
    skip: !isLoggedIn
  })

  if (!isLoggedIn || loading)
    return <FullPageLoader message="Loading past parking codes" />

  const { pastAccountPromoCodes } = pastPromoData

  const renderEmpty = () => (
    <div className={styles.emptyContainer}>
      <h5>No Expired or Used Passes</h5>
      <p>There are no expired or used parking codes in your account history.</p>
    </div>
  )

  const renderPastCodes = () =>
    pastAccountPromoCodes.map((promoCode) => {
      return <PromoCodeCard key={promoCode.hashid} promoCode={promoCode} />
    })

  return (
    <div className="d-flex flex-column h-100">
      <Helmet title="Past Passes" />
      <Hero>
        <Hero.Headline className="px-5">Past Parking Codes</Hero.Headline>
      </Hero>
      <div
        className={classNames(
          styles.body,
          "container-sm py-4 d-flex flex-column"
        )}
      >
        <div className={styles.linkWrapper}>
          <Link to="/code" className="fw-bolder">
            Enter New Parking Code
          </Link>
          &nbsp;or&nbsp;
          <Link to="/parking-codes" className="fw-bolder">
            View Active Parking Codes
          </Link>
        </div>
        <div className={styles.cardsContainer}>
          {!isEmpty(pastAccountPromoCodes) && renderPastCodes()}
          {isEmpty(pastAccountPromoCodes) && renderEmpty()}
        </div>
      </div>
    </div>
  )
}

export default PastAccountPromoCodes
