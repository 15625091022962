import { useEffect } from "react"
import { useLocation, Redirect } from "react-router-dom"
import Helmet from "react-helmet"
import isEmpty from "lodash/isEmpty"
import qs from "query-string"
import RSVPConfig from "RSVPConfig"
import { AUTH_KEY } from "shared/constants"
import FullPageLoader from "components/shared/FullPageLoader"
import { useForceAuthEffect } from "components/Login/hooks"

const host = RSVPConfig.sisterSite.url
const REDIRECT_PATH = "/auth-consume"

const SisterSiteRedirect = () => {
  useForceAuthEffect()
  // expects oaTag param and r param (for the redirect post-consume)
  const { pathname, search } = useLocation()
  const searchParams = qs.parse(search)
  const { oaTag, r } = searchParams

  useEffect(() => {
    const url = new URL(REDIRECT_PATH, host)
    url.search = qs.stringify({ oaTag, r })

    if (r && oaTag) {
      window.location.assign(url)
    }
  }, [r, oaTag])

  const needsAuthParam = isEmpty(oaTag)

  if (needsAuthParam)
    return (
      <Redirect
        to={{
          pathname,
          search: qs.stringify({ oaTag: localStorage.getItem(AUTH_KEY), r })
        }}
      />
    )

  return (
    <>
      <Helmet title="Logging in" />
      <FullPageLoader
        message="Logging in"
      />
    </>
  )
}

export default SisterSiteRedirect
