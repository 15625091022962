import { Link, useHistory } from "react-router-dom"
import { Helmet } from "react-helmet"
import classNames from "classnames"
import { useQuery, useMutation } from "@apollo/client"
import { useEffect, useCallback } from "react"
import isEmpty from "lodash/isEmpty"
import { clearOnLogout } from "apolloClient"

import { avoidPreRedirectUrl, sisterSiteRedirect } from "shared/helpers/url"
import { AUTH_PATHS } from "shared/constants"
import { useLoginState } from "components/Login/hooks"
import styles from "./Settings.module.scss"
import { Account, Logout } from "./operations"

const Settings = () => {
  const [isLoggedIn] = useLoginState()
  const history = useHistory()
  const handleBackNav = history.goBack

  const [logoutUser, { client }] = useMutation(Logout, {
    onCompleted: () => {
      sisterSiteRedirect(history, AUTH_PATHS.logout)
    }
  })
  const { loading, data } = useQuery(Account)

  const logout = useCallback(() => {
    clearOnLogout(client)
    logoutUser({
      variables: {
        input: {}
      }
    })
  }, [client, logoutUser])

  useEffect(() => {
    if (data && isEmpty(data.me)) logout()
  }, [data, logout])

  useEffect(() => {
    if (!isLoggedIn) {
      history.replace("/")
    }
  }, [isLoggedIn, history])

  return (
    <div className="h-100">
      <Helmet title="Settings" />
      <div className={styles.item}>
        <div
          className={classNames("text-decoration-none", styles.menuLink)}
          onClick={handleBackNav}
        >
          <i className="bi-chevron-left" />
          &nbsp;Back
        </div>
      </div>
      <div className={styles.menuGroupLink}>
        <Link
          to="/parking-codes"
          className={classNames(
            "text-decoration-none",
            "text-center",
            styles.menuLink,
            styles.addMargin
          )}
        >
          View parking codes
        </Link>
        <Link
          to="/parking-reservations"
          className={classNames(
            "text-decoration-none",
            "text-center",
            styles.menuLink,
            styles.addMargin
          )}
        >
          Manage parking reservations
        </Link>
        <Link
          to={avoidPreRedirectUrl(`/account`, {
            destUrl: `${process.env.REACT_APP_RSVP_BASE}`
          })}
          target="_blank"
          className={classNames(
            "text-decoration-none",
            "text-center",
            styles.menuLink,
            styles.addMargin
          )}
        >
          Profile
        </Link>
        <a
          href={`${process.env.REACT_APP_COREAPP_BASE}/support/contact-us`}
          className={classNames(
            "text-decoration-none",
            "text-center",
            styles.menuLink,
            styles.addMargin
          )}
          target="_blank"
          rel="noreferrer"
        >
          Support
        </a>
        <Link
          to="/"
          onClick={logout}
          className={classNames(
            "text-decoration-none",
            "text-center",
            styles.menuLink,
            styles.addMargin
          )}
        >
          Logout
        </Link>
      </div>
      {!loading && (
        <div className="text-muted mt-5 mb-3 text-center">
          Logged in as {data.me?.emailAddress}
        </div>
      )}
    </div>
  )
}

export default Settings
