const config = {
  name: "park-city",
  features: {
    accessCodes: false,
    moreInformation: true,
    hasSubheader: true,
    hasSubtitle: true,
    hasParkingInstructions: true,
    hasPromoCodeWarning: false,
    userPhoneNumber: true
  },
  parkingSession: {
    // that day, just before 8am
    cancellationDeltaMs: 0,
    // Start time offset 8am
    startOffsetHrs: 8
  },
  sisterSite: {
    url: null,
    name: null
  }
}
export default config
