const config = {
  name: "crystal",
  features: {
    accessCodes: false,
    moreInformation: false,
    hasSubheader: false,
    hasSubtitle: false,
    hasParkingInstructions: true,
    hasPromoCodeWarning: false,
    userPhoneNumber: true
  },
  parkingSession: {
    // 20 hours, i.e 12pm of prior day
    cancellationDeltaMs: 72000000,
    // Start time offset from midnight, i.e. 8 am
    startOffsetHrs: 8
  },
  sisterSite: {
    url: null,
    name: null
  }
}
export default config
