import isEmpty from "lodash/isEmpty"
import get from "lodash/get"
import omit from "lodash/omit"
import some from "lodash/some"
import filter from "lodash/filter"

import { BEHAVIOUR_TYPE } from "shared/constants"

/**
 * availability data structure
  const dayData = {
    "jmY3iN": {
      "available": true,
      "hashid": "jmY3iN",
      "description": "RSVP rate test",
      "price": "0.0",
      "behaviour_type": "purchase"
    },
    "d84kF9": {
      "available": false,
      "hashid": "d84kF9",
      "description": "New Rate",
      "price": "5.0",
      "behaviour_type": "purchase"
    },
    "general": {
      "has_private_rates": true
      "sold_out": false
      "allow_calendar": true
    }
  }
 */

// Returns true if any of the public rates are available
// export const isAnyPublicRateAvailable = (availability) => {
//   if (isEmpty(availability)) return false

//   const ratesData = omit(availability, ["general"])
//   return some(ratesData, (rate) => rate.available)
// }

// Returns true if any of the public rates are unavailable
export const isAnyPublicRateUnavailable = (availability) => {
  if (isEmpty(availability)) return false

  const ratesData = omit(availability, ["general"])
  return some(ratesData, (rate) => !rate.available)
}

// Returns true if the private rates are available
export const hasPrivateRates = (availability) => {
  if (isEmpty(availability)) return false

  return get(availability, "general.has_private_rates", false)
}

// Returns true if the general availability is sold out
export const isSoldOut = (availability) => {
  if (isEmpty(availability)) return false

  return get(availability, "general.sold_out", false)
}

// Returns true if the there are any notification rates
export const isNotificationRate = (rates) => {
  if (isEmpty(rates)) return false

  return some(
    rates,
    (rate) => rate.behaviourType === BEHAVIOUR_TYPE.notification
  )
}

// Returns an array of unavailable private rates
export const getUnavailableRates = (availability) => {
  if (isEmpty(availability)) return []

  const ratesData = omit(availability, ["general"])
  const unavailableRates = filter(ratesData, (rate) => !rate.available)

  return unavailableRates
}

// New helper functions
export const isAnyPublicRateUnavailableNew = (availability) => {
  if (isEmpty(availability)) return false

  const ratesData = omit(availability, ["status"])
  return some(ratesData, (rate) => !rate.available)
}

export const isNotificationRateNew = (rates) => {
  if (isEmpty(rates)) return false

  return some(
    rates,
    (rate) => rate.behaviourType === BEHAVIOUR_TYPE.notification
  )
}

export const isNotNeededNotificationRate = (rates) => {
  if (isEmpty(rates)) return false

  return some(
    rates,
    (rate) =>
      rate.behaviourType === BEHAVIOUR_TYPE.notification && rate.freeFlag
  )
}

// Returns an array of unavailable private rates
export const getUnavailableRatesNew = (availability) => {
  if (isEmpty(availability)) return []

  const ratesData = omit(availability, ["status"])
  const unavailableRates = filter(ratesData, (rate) => !rate.available)

  return unavailableRates
}
