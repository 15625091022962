import { Helmet } from "react-helmet"
import { useQuery } from "@apollo/client"
import orderBy from "lodash/orderBy"
import partition from "lodash/partition"
import isEmpty from "lodash/isEmpty"
import { DateTime } from "luxon"

import Hero from "components/shared/Hero"
import FullPageLoader from "components/shared/FullPageLoader"
import ErrorToast from "components/shared/ErrorToast"
import { useForceAuthEffect, useLoginState } from "components/Login/hooks"
import RSVPConfig from "RSVPConfig"
import SessionCard from "./SessionCard"
import { RsvpPortalParkingSessions } from "./operations"

import styles from "./ParkingSessions.module.scss"

const ParkingSessions = () => {
  useForceAuthEffect({ authUrl: "/login" })
  const [isLoggedIn] = useLoginState()
  const { loading, data } = useQuery(RsvpPortalParkingSessions, {
    variables: { rsvpPortalId: process.env.REACT_APP_RSVP_PORTAL_ID },
    skip: !isLoggedIn
  })

  if (!isLoggedIn || loading)
    return <FullPageLoader message="Loading parking reservations" />

  const { rsvpPortalParkingSessions } = data

  const partitionedSessions = partition(
    rsvpPortalParkingSessions,
    (session) =>
      DateTime.fromISO(session.endTime) >= DateTime.now() && !session.voided
  )

  const activeSessions = orderBy(partitionedSessions[0], ["startTime"], ["asc"])
  const oldSessions = orderBy(partitionedSessions[1], ["startTime"], ["desc"])

  return (
    <div className="d-flex flex-column h-100">
      <Helmet title="Parking Reservations" />
      <Hero>
        <Hero.Headline>Parking Reservations</Hero.Headline>
      </Hero>

      {!isEmpty(RSVPConfig.sisterSite.url) &&
        !isEmpty(RSVPConfig.sisterSite.name) && (
          <div className={styles.sisterSiteContainer}>
            <p className={styles.sisterSite}>
              {`Don’t see your session? \n Check our other lot.`}
            </p>
            <a href={`${RSVPConfig.sisterSite.url}/parking-reservations`}>
              {RSVPConfig.sisterSite.name}
            </a>
          </div>
        )}

      <div className="container-sm">
        <div className="row justify-content-center">
          <div className="col-md-8 col-xl-6">
            <div className={styles.content}>
              {!isEmpty(activeSessions) && (
                <div className="mb-5">
                  <h5 className="fw-bold">Active Sessions</h5>
                  {activeSessions.map((session) => (
                    <SessionCard session={session} key={session.hashid} />
                  ))}
                </div>
              )}
              {!isEmpty(oldSessions) && (
                <>
                  <h5 className="fw-bold">Previous Sessions</h5>
                  {oldSessions.map((session) => (
                    <SessionCard session={session} key={session.hashid} />
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <ErrorToast />
    </div>
  )
}

export default ParkingSessions
