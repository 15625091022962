import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import formatPrice from "shared/formatting/price"

import styles from "./SelectRate.module.scss"

const RateCard = ({ rate, className, onClick, available = true }) => {
  const { description, price, hashid } = rate
  return (
    <div
      key={hashid}
      className={classNames(styles.card, className)}
      onClick={onClick}
    >
      <div className={styles.rateCopy}>
        <div>{description}</div>
        {!available && <div className={styles.soldout}>SOLD OUT</div>}
      </div>
      <div className={styles.ratePrice}>{formatPrice({ value: price })}</div>
    </div>
  )
}

RateCard.propTypes = {
  rate: PropTypes.object,
  className: PropTypes.string,
  onClick: PropTypes.func,
  available: PropTypes.bool
}

export default RateCard
