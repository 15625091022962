import qs from "query-string"
import isEmpty from "lodash/isEmpty"
import RSVPConfig from "RSVPConfig"
import { AUTH_KEY, AUTH_PATHS } from "shared/constants"
import { urlAfterAuth } from "../../components/Login/hooks"

const urlSisterSite = RSVPConfig.sisterSite.url

const REDIRECT_PATHS = {
  login: "/auth-redirect",
  logout: "/logout-redirect"
}

export const generateHonkRedirectQuery = (url, { destUrl, originUrl = "" }) => {
  const encodedDestUrl = encodeURIComponent(destUrl)
  const encodedOriginUrl = encodeURIComponent(originUrl)
  const r = encodeURIComponent(
    `${url}?destUrl=${encodedDestUrl}&originUrl=${encodedOriginUrl}`
  )

  const oaTag = localStorage.getItem(AUTH_KEY)

  return qs.stringify({ r, oaTag })
}

// /redirect-to-honk?oaTag=:oaTag&r=/checkout/:cartId?destUrl=https://alta.honkmobile.com/return-to-honk
export const generateRedirectUrl = (url, { destUrl, originUrl }) => {
  return {
    pathname: "/redirect-to-honk",
    search: generateHonkRedirectQuery(url, { destUrl, originUrl })
  }
}

export const avoidPreRedirectUrl = (url, { destUrl, originUrl }) => {
  return {
    pathname: `${process.env.REACT_APP_COREAPP_BASE}/consume`,
    search: generateHonkRedirectQuery(url, { destUrl, originUrl })
  }
}

// Sister site login and logout flow

/**
 * Palisades Tahoe, Park City Mountin and Northstar have two portals
 * Portals are completely isolated so one doesn’t know if you have logged into another
 * User needs to login or register two times to avoid this we are using a sister site login and logout flow
 */

/**
 * Generates a URL query string based on the provided destination URL and an optional oaTag.
 *
 * - Encodes the `destUrl` and sets it as the value for the `r` parameter.
 * - If `includeOaTag` is true, the oaTag is retrieved from local storage and added to the parameters.
 * - The parameters object is then converted into a URL query string using the `qs.stringify` method.
 *
 * destUrl - The destination URL to encode and include in the query string.
 * [includeOaTag=true] - Determines whether the oaTag should be included in the query string. Defaults to true.
 *
 * - Returns a URL query string.
 */
export const generateRedirectQuery = ({ destUrl, includeOaTag = true }) => {
  const r = encodeURIComponent(destUrl)
  const params = { r }

  // If includeOaTag is true, then we add the oaTag to the params object
  if (includeOaTag) {
    params.oaTag = localStorage.getItem(AUTH_KEY)
  }

  return qs.stringify(params)
}

/**
 * Generates a URL for redirecting the user on the sister site based on the specified type and destination URL.
 *
 * - Constructs the pathname based on the provided `type` by mapping it to its corresponding path in `REDIRECT_PATHS`.
 * - Constructs the search query parameters for the URL using the `generateRedirectQuery` function, which factors in the
 *   destination URL (`destUrl`) and whether the type matches a login path.
 *
 * destUrl - The destination URL to redirect to after the action on the sister site.
 * [type=AUTH_PATHS.login] - Specifies the type of authentication path, defaulting to login.
 *
 * - Returns an object with the properties `pathname` and `search`, ready to be used with React Router's redirect methods.
 */
export const generateSisterSiteRedirectUrl = ({
  destUrl,
  type = AUTH_PATHS.login
}) => {
  return {
    pathname: REDIRECT_PATHS[type],
    search: generateRedirectQuery({
      destUrl,
      includeOaTag: AUTH_PATHS.login
    })
  }
}

/**
 * Redirects the user to the appropriate URL on a sister site based on the given type and other conditions.
 *
 * - First, it checks if the `urlSisterSite` is populated. If not, the function exits without performing a redirect.
 * - Constructs the `postLoginUrl` which combines the base RSVP URL with a specific post-auth URL (honk checkout or manage reservations in RSVP).
 * - Determines the `destinationUrl` based on whether `urlAfterAuth()` provides a value and if the `type` matches a login path.
 *   + If both conditions are met, the user is redirected to `postLoginUrl`.
 *   + Otherwise, they are redirected to the base URL of the RSVP application (which is home page of RSVP app).
 * - Finally, using the determined `destinationUrl`, a final `redirectUrl` is generated and the user is redirected to it.
 *
 * history - The history object from React Router to facilitate the redirect.
 * type - Specifies the type of authentication path, defaulting to login.
 */
export const sisterSiteRedirect = (history, type = AUTH_PATHS.login) => {
  if (!isEmpty(urlSisterSite)) {
    const baseURL = process.env.REACT_APP_RSVP_BASE
    const afterAuthURL = urlAfterAuth()

    const postLoginUrl = `${baseURL}${afterAuthURL}`
    const originSisterSiteUrl = `${baseURL}/`

    // Determine the destination URL based on the presence of afterAuthURL and if the `type` matches a login path
    const destinationUrl =
      !isEmpty(afterAuthURL) && type === AUTH_PATHS.login
        ? postLoginUrl
        : originSisterSiteUrl

    const redirectUrl = generateSisterSiteRedirectUrl({
      destUrl: destinationUrl,
      type
    })

    history.replace(redirectUrl)
  }
}
