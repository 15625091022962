import { useEffect } from "react"
import PropTypes from "prop-types"
import { useQuery, useLazyQuery } from "@apollo/client"
import { Link } from "react-router-dom"
import Helmet from "react-helmet"
import classNames from "classnames"
import isEmpty from "lodash/isEmpty"
import get from "lodash/get"

import FullPageLoader from "components/shared/FullPageLoader"
import { useConsumePromoCode } from "components/AddPromoCode/hooks"
import { useLoginState } from "components/Login/hooks"

import Hero from "components/shared/Hero"
import PromoCodeCard from "components/shared/PromoCodeCard"
import ErrorToast from "components/shared/ErrorToast"
import { accountPromoCodeVar } from "cache"
import {
  AccountPromoCodes as AccountPromoCodesQuery,
  ReadCurrentAccountPromoCodes
} from "./operations"

import styles from "./AccountPromoCodes.module.scss"

const AccountPromoCodes = ({ history }) => {
  const [isLoggedIn] = useLoginState()
  const [addPromoCode, { loading: consumeLoading }] = useConsumePromoCode()
  const [getAccountPromoCodes, { loading: apcLoading }] = useLazyQuery(
    AccountPromoCodesQuery
  )
  const { data: currentCodesData } = useQuery(ReadCurrentAccountPromoCodes, {
    variables: { rsvpPortalId: process.env.REACT_APP_RSVP_PORTAL_ID }
  })
  const currentAccountPromoCodes = get(
    currentCodesData,
    "currentAccountPromoCodes"
  )

  useEffect(() => {
    const promoCode = accountPromoCodeVar()
    // Even thought it's after the useForceAuthEffect call,
    // this will fire before the redirect happens
    if (isLoggedIn) {
      const addAndLoadPromo = async () => {
        if (promoCode) {
          await addPromoCode({
            variables: {
              input: {
                promoCode,
                rsvpPortalId: process.env.REACT_APP_RSVP_PORTAL_ID
              }
            },
            refetchQueries: [
              {
                query: AccountPromoCodesQuery,
                variables: {
                  rsvpPortalId: process.env.REACT_APP_RSVP_PORTAL_ID
                },
                fetchPolicy: "network-only"
              }
            ],
            awaitRefetchQueries: true
          })
        }
        getAccountPromoCodes({
          variables: { rsvpPortalId: process.env.REACT_APP_RSVP_PORTAL_ID }
        })
      }
      addAndLoadPromo()
    }
  }, [addPromoCode, getAccountPromoCodes, isLoggedIn])

  useEffect(() => {
    // Redirect to login page if not logged in
    if (!isLoggedIn) {
      history.replace("/login")
    }
  }, [isLoggedIn, history])

  // TODO: Maybe change messages?
  if (consumeLoading) return <FullPageLoader message="Claiming parking code" />
  if (apcLoading) return <FullPageLoader message="Retrieving parking codes" />

  return (
    <div className="h-100 bg-light d-flex flex-column">
      <Helmet title="Passes" />
      <Hero>
        <Hero.Headline className="px-5">Select a Parking Code</Hero.Headline>
      </Hero>
      <div
        className={classNames(
          styles.body,
          "container-sm py-4 d-flex flex-column"
        )}
      >
        <div className={styles.linkWrapper}>
          <Link to="/code" className="fw-bolder">
            Enter New Parking Code
          </Link>
          &nbsp;or&nbsp;
          <Link to="/parking-codes/past" className="fw-bolder">
            View Past Parking Codes
          </Link>
        </div>
        <div className={styles.cardsContainer}>
          {!isEmpty(currentAccountPromoCodes) &&
            currentAccountPromoCodes.map((promoCode) => {
              return (
                <PromoCodeCard key={promoCode.hashid} promoCode={promoCode} />
              )
            })}
          {isEmpty(currentAccountPromoCodes) && (
            <div className={styles.emptyContainer}>
              <h5>No Active Parking Codes</h5>
              <p>
                No active parking codes found on your account. Enter a new
                parking code or reserve parking through general admission.
              </p>
            </div>
          )}
        </div>
      </div>
      <ErrorToast />
      <div className={styles.selectParkingLinkContainer}>
        <Link to="/select-parking" className={styles.selectParkingLink}>
          Reserve without a code
        </Link>
      </div>
    </div>
  )
}

AccountPromoCodes.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired
  }).isRequired
}

export default AccountPromoCodes
