import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import { Helmet } from "react-helmet"
import { useApolloClient } from '@apollo/client'
import { clearOnLogout } from "apolloClient"
import qs from "query-string"
import FullPageLoader from "components/shared/FullPageLoader"

const SisterSiteLogoutConsume = () => {
  const client = useApolloClient()
  const { search } = useLocation()
  useEffect(() => {
    const { r: destinationUrl } = qs.parse(search)
    const destUrl = decodeURIComponent(destinationUrl)

    clearOnLogout(client)
    window.location.assign(destUrl)
  }, [])

  return (
    <>
      <Helmet title="Logging out" />
      <FullPageLoader
        message="Logging out"
      />
    </>
  )
}

export default SisterSiteLogoutConsume
