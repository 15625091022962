const config = {
  name: "northstar",
  features: {
    accessCodes: true,
    moreInformation: true,
    hasSubheader: false,
    hasSubtitle: true,
    hasParkingInstructions: true,
    hasPromoCodeWarning: false,
    userPhoneNumber: false
  },
  parkingSession: {
    // that day, just before 8am
    cancellationDeltaMs: 0,
    // Start time offset from midnight, i.e. 8 am
    startOffsetHrs: 8
  },
  sisterSite: {
    url: process.env.REACT_APP_SISTER_SITE_URL,
    name: "Northstar \n Preferred"
  }
}
export default config
