import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import Helmet from "react-helmet"
import qs from "query-string"
import RSVPConfig from "RSVPConfig"
import FullPageLoader from "components/shared/FullPageLoader"

const host = RSVPConfig.sisterSite.url
const REDIRECT_PATH = "/logout-consume"

const SisterSiteLogoutRedirect = () => {
  // expects r param (for the redirect post-clear-session)
  const { search } = useLocation()
  const searchParams = qs.parse(search)
  const { r } = searchParams

  useEffect(() => {
    const url = new URL(REDIRECT_PATH, host)
    url.search = qs.stringify({ r })

    if (r) {
      window.location.assign(url)
    }
  }, [r])

  return (
    <>
      <Helmet title="Logging out" />
      <FullPageLoader
        message="Logging out"
      />
    </>
  )
}

export default SisterSiteLogoutRedirect
